import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/authActions";

function useAxios() {
  const LSToken = localStorage.getItem("token");
  const SSToken = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || LSToken || SSToken;

  axios.interceptors.response.use((req) => {
    if (req.status === 401) dispatch(logout());
    return req;
  });

  axios.defaults.headers = {
    /*"Content-Type": "application/json",*/
    ...(token && { authorization: token }),
  };
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/`;
}

export default useAxios;
